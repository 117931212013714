// Path: /src/utils/meta/metaPixelEvent.js
export const completeRegistration = () => {
    window.fbq('track', 'CompleteRegistration');
};  

// Path: /src/utils/meta/metaPixelEvent.js
export const addToCart = (currency, value) => {
    window.fbq('track', 'AddToCart', { currency, value });
};

// Path: /src/utils/meta/metaPixelEvent.js
export const initiateCheckout = (currency, value) => {
    window.fbq('track', 'InitiateCheckout', { currency, value });
};

// Path: /src/utils/meta/metaPixelEvent.js
export const purchase = (currency, value) => {
    window.fbq('track', 'Purchase', { currency, value });
};

// Path: /src/utils/meta/metaPixelEvent.js
export const addPaymentInfo = () => {
    window.fbq('track', 'AddPaymentInfo');
};