import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
  
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

export default function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // cssEase: "linear"
  };
  return (
    <div className="story-block-inner bg-white shadow-lg py-5 rounded-3">
      <Slider {...settings}>
          <div className="story-block px-5 ">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/slider/1.jpg')} alt="jen"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Moira N.</h4>
                  <p style={{fontSize: 14}}>“I was so excited to hear I had won a signed shirt!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Signed Shirt</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/slider/3.jpg')} alt="jonny"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Claire Y.</h4>
                  <p style={{fontSize: 14}}>“I was very happy to win the jackpot- we always buy the tickets to support local causes.”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">£542.72</p>
                </div>
              </div>
            </div>
          </div>

          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/slider/2.jpeg')} alt="steve"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Smantha T.</h4>
                  <p style={{fontSize: 14}}>“I was over the moon to find out I had won and wasn’t expecting a cash prize!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">£100</p>
                </div>
              </div>

            </div>
          </div>

      </Slider>
    </div>
  );
}
